<template>
  <div id="home">
    <h1>{{ $t('views.home.welcome') }}</h1>
    <p>{{ $t('views.home.welcome_message') }}</p>
    <b-alert show variant="warning" v-if="$store.state.user && !$store.state.token">
      <strong>{{ $t('system.notice') }}!</strong><br/>{{ $t('views.home.note_text') }}
    </b-alert>
    <template v-else>
      <b-row>
        <b-col>
          <h2>{{ $tc('system.bcd', 2) }}</h2>
        </b-col>
        <b-col>
        <span class="float-right">
        <b-button-toolbar>
          <b-button-group class="mx-1">
            <b-button variant="outline-secondary" @click="$store.commit('setHomeGridMode', true)"
                      :pressed="$store.state.home_grid" active-class="button-active"><netvs-icon
                icon="grid"/></b-button>
            <b-button variant="outline-secondary" @click="$store.commit('setHomeGridMode', false)"
                      :pressed="!$store.state.home_grid"><netvs-icon icon="list"/></b-button>
          </b-button-group>
        </b-button-toolbar>
        </span>
        </b-col>
      </b-row>
      <Loading :data="bcds">
        <FilterInput v-model="filter"></FilterInput>
        <Paginator :current-page="bcd_page" :per-page="bcd_per_page" @current-page="bcd_page = $event"
               @per-page="bcd_per_page = $event" :total-rows="bcds_filtered.length"
               v-if="bcds_filtered && bcds_filtered.length > bcd_per_page"
                     :per-page-options="[6, 24, 48, 96, 384, { value: 0, translation_key: 'components.paginator.all' }]" />
        <div v-if="bcds_filtered.length === 0" class="text-muted fill text-center">{{ $t('views.home.no_bcds') }}</div>
        <b-table responsive :items="bcds_paged" :fields="bcd_fields" v-if="!$store.state.home_grid" :sort-by="name">
          <template #cell(vni)="data">
            <BCDId :netcmpnds_by_net_instnc="netcmpnds_by_net_instnc" :netcmpnds_by_name="netcmpnds_by_name" :bcd_vlans="bcd2vlans[data.item.name] || []" :bcd="data.item" :bcd_vni="bcd2vni[data.item.name]"/>
          </template>
          <template #cell(categ)="data">
            {{ data.value }} – {{ $t_netdb(bcd_categ_by_name[data.value].description) }}
          </template>
          <template #cell(seclvl)="data">
            {{ data.value }} – {{ $t_netdb(bcd_seclvl_by_value[data.value].description) }}
          </template>
          <template #cell(actions)="data">
            <b-button-group class="d-flex">
              <b-button variant="outline-secondary" :id="'show-evlog-bcd-' + data.item.name"
                        @click="showEVLog(data.item)">
                <netvs-icon icon="evlog"></netvs-icon>
              </b-button>
              <b-tooltip :target="'show-evlog-bcd-' +  data.item.name" triggers="hover"
                         variant="secondary" placement="bottom">
                {{ $t('system.event_log') }}
              </b-tooltip>
              <b-button @click="editBCD(data.item)" variant="outline-primary"
                        :id="'button-edit-bcd-' +  data.item.name">
                <netvs-icon icon="edit"></netvs-icon>
              </b-button>
              <b-tooltip :target="'button-edit-bcd-' +  data.item.name" triggers="hover"
                         variant="primary" placement="bottom">
                {{ $t('views.home.edit_bcd') }}
              </b-tooltip>
            </b-button-group>
          </template>
          <template #cell(systems)="data">
            <ul class="list-unstyled" style="min-width: 150px">
              <li v-if="$store.state.sysinfo.js_mods.includes('dnsvs')">
                <b-link :to="`/dnsvs/bcds/${data.item.name}`">
                  <netvs-icon icon="dnsvs"></netvs-icon>
                  {{ $t('system.dnsvs') }}
                </b-link>
              </li>
              <li v-if="$store.state.sysinfo.js_mods.includes('macauth')">
                <b-link :to="`/macauth/bcds/${data.item.name}`">
                  <netvs-icon icon="macauth"></netvs-icon>
                  {{ $t('system.macauth') }}
                </b-link>
              </li>
              <li v-if="$store.state.sysinfo.js_mods.includes('dhcp_leases')">
                <b-link :to="`/dhcp-leases/bcds/${data.item.name}`">
                  <netvs-icon icon="dhcp_leases"></netvs-icon>
                  {{ $t('system.dhcp_leases') }}
                </b-link>
              </li>
              <li>
                <b-link
                    target="_blank"
                    :href="$store.state.sysinfo.netdb_admin_base + `/~netadmin/natvs/user/wrapper.cgi/${data.item.name}/0/${data.item.name}/0/`">
                  <netvs-icon icon="natvs"></netvs-icon>
                  {{ $t('system.natvs_plus') }}
                </b-link>
              </li>
              <li v-if="$store.state.sysinfo.js_mods.includes('netdoc')">
                <b-link :to="`/netdoc/bcds/${data.item.name}`">
                  <netvs-icon icon="netdoc"></netvs-icon>
                  {{ $t('system.netdoc') }}
                </b-link>
              </li>
            </ul>
          </template>
        </b-table>
        <b-row v-if="$store.state.home_grid">
          <b-col lg="4" md="6" v-for="b in bcds_paged" :key="b.gpk">
            <b-card style="height: 100%;">
              <template v-slot:header>
                <b-row>
                  <b-col class="mb-0" lg="7">
                    <h4>
                      {{ b.name }}
                    </h4>
                    <p v-if="b.description && b.description != ''">
                      {{ b.description }}
                    </p>
                    <p v-else class="text-muted">{{ $t('views.home.no_description') }}</p>
                    <BCDId :netcmpnds_by_net_instnc="netcmpnds_by_net_instnc" :netcmpnds_by_name="netcmpnds_by_name" :bcd_vlans="bcd2vlans[b.name] || []" :bcd="b" :bcd_vni="bcd2vni[b.name]"/>
                  </b-col>
                  <b-col lg="5" class="mb-0">
                    <b-button-group class="d-flex">
                      <b-button variant="outline-secondary" :id="'show-evlog-bcd-' + b.name"
                                @click="showEVLog(b)">
                        <netvs-icon icon="evlog"></netvs-icon>
                      </b-button>
                      <b-tooltip :target="'show-evlog-bcd-' +  b.name" triggers="hover"
                                 variant="secondary" placement="bottom">
                        {{ $t('system.event_log') }}
                      </b-tooltip>
                      <b-button @click="editBCD(b)" variant="outline-primary"
                                :id="'button-edit-bcd-' +  b.name">
                        <netvs-icon icon="edit"></netvs-icon>
                      </b-button>
                      <b-tooltip :target="'button-edit-bcd-' +  b.name" triggers="hover"
                                 variant="primary" placement="bottom">
                        {{ $t('views.home.edit_bcd') }}
                      </b-tooltip>
                    </b-button-group>
                  </b-col>
                </b-row>
                <b-row class="mt-0">
                  <b-col :title="$t('views.home.bcd_categ')">
                    <netvs-icon icon="bcd_categ"/>
                    {{ bcd_categ_by_name[b.categ].name }} – {{ $t_netdb(bcd_categ_by_name[b.categ].description) }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col :title="$t('views.home.bcd_seclvl')">
                    <netvs-icon icon="bcd_seclvl"/>
                    {{ bcd_seclvl_by_value[b.seclvl].value }} – {{
                      $t_netdb(bcd_seclvl_by_value[b.seclvl].description)
                    }}
                  </b-col>
                </b-row>
              </template>
              <b-list-group>
                <b-list-group-item :to="'/dnsvs/bcds/' + b.name" v-if="$store.state.sysinfo.js_mods.includes('dnsvs')">
                  <netvs-icon icon="dnsvs"></netvs-icon>
                  {{ $t('system.dnsvs') }}
                </b-list-group-item>
                <b-list-group-item :to="'/macauth/bcds/' + b.name" v-if="$store.state.sysinfo.js_mods.includes('macauth')">
                  <netvs-icon icon="macauth"></netvs-icon>
                  {{ $t('system.macauth') }}
                </b-list-group-item>
                <b-list-group-item :to="'/dhcp-leases/bcds/' + b.name" v-if="$store.state.sysinfo.js_mods.includes('dhcp_leases')">
                  <netvs-icon icon="dhcp_leases"></netvs-icon>
                  {{ $t('system.dhcp_leases') }}
                </b-list-group-item>
                <b-list-group-item
                    target="_blank"
                    :href="$store.state.sysinfo.netdb_admin_base + `/~netadmin/natvs/user/wrapper.cgi/${b.name}/0/${b.name}/0/`"
                >
                  <netvs-icon icon="natvs"></netvs-icon>
                  {{ $t('system.natvs_plus') }}
                </b-list-group-item>
                <b-list-group-item :to="'/netdoc/bcds/' + b.name" v-if="$store.state.sysinfo.js_mods.includes('netdoc')">
                  <netvs-icon icon="netdoc"></netvs-icon>
                  {{ $t('system.netdoc') }}
                </b-list-group-item>
              </b-list-group>
            </b-card>
          </b-col>
        </b-row>
      </Loading>
    </template>
    <DBEditor :presets="edit_bcd?edit_bcd:{}" modal_id="edit_bcd"
              :object_title="edit_bcd?edit_bcd.name:''"
              object_function="update" object_fq_name="nd.bcd" :old_data="edit_bcd?edit_bcd:{}"
              :non_optionals_order="['description']">
    </DBEditor>
    <EVLogViewer modal_id="evlog_bcd" :title="'BCD \''+ (evlog_bcd?evlog_bcd.name:'') + '\''"
                 ref_obj_fq="nd.bcd" :refobj_id_value="evlog_bcd?evlog_bcd.gpk:null"></EVLogViewer>
  </div>
</template>

<script>
import Vue from 'vue'
import BCDService from '@/api-services/dns_bcd.service'
import Loading from '@/components/Loading'
import FilterInput from '@/components/FilterInput'
import EVLogViewer from '../components/EVLogViewer'
import DBEditor from '../components/db-editor/APIObjectDBEditor.vue'
import apiutil from '@/util/apiutil'
import BCDId from '@/components/BCDId.vue'
import Paginator from '@/components/Paginator.vue'

export default {
  name: 'NETVSHome',
  components: { BCDId, DBEditor, EVLogViewer, FilterInput, Loading, Paginator},
  data() {
    return {
      netcmpnds_by_net_instnc: {},
      netcmpnds_by_name: {},
      bcd_page: 1,
      bcd_per_page: 24,
      evlog_bcd: null,
      edit_bcd: null,
      filter: '',
      bcds: [],
      bcd2vlans: {},
      bcd2vni: {},
      bcd_seclvl_by_value: {},
      bcd_categ_by_name: {},
      bcd_fields: [
        {
          label: 'ID',
          key: 'vni',
          sortable: false
        },
        {
          key: 'name',
          label: this.$t('system.name'),
          sortable: true
        },
        {
          key: 'description',
          label: this.$t('system.description'),
          sortable: true
        },
        {
          key: 'categ',
          label: this.$t('views.home.bcd_categ'),
          sortable: true,
          formatter: (value) => {
            return this.bcd_categ_by_name[value].name
          }
        },
        {
          key: 'seclvl',
          label: this.$t('views.home.bcd_seclvl'),
          sortable: true,
          formatter: (value) => {
            return this.bcd_seclvl_by_value[value].value
          }
        },
        {
          key: 'systems',
          label: this.$tc('system.system', 2),
        },
        {
          key: 'actions',
          label: this.$tc('system.action', 2)
        }
      ]
    }
  },
  computed: {
    bcds_filtered() {
      return this.bcds.filter((b) => {
        return b.name.includes(this.filter)
      })
    },
    bcds_paged() {
      if (this.bcd_per_page === 0) {
        return this.bcds_filtered
      }
      return this.bcds_filtered.slice((this.bcd_page - 1) * this.bcd_per_page, this.bcd_page * this.bcd_per_page)
    }
  },
  methods: {
    showEVLog(bcd) {
      this.evlog_bcd = bcd
      Vue.nextTick(() => {
        this.$root.$emit('bv::show::modal', 'evlog_bcd')
      })
    },
    editBCD(bcd) {
      this.edit_bcd = bcd
      this.$root.$emit('bv::show::modal', 'edit_bcd')
    }
  },
  watch: {
    filter(new_value) {
      this.bcd_page = 1
    }
  },
  async created() {
    if (!this.$store.state.user) {
      this.$router.push('/login')
      return
    }
    if (this.$store.state.user && this.$store.state.token) {
      const res = (await BCDService.getOverview(this.$store.state)).data
      this.bcds = res.bcd_list
      this.bcd2vlans = apiutil.dict_of_lists_by_value_of_array(res.vlan_list, 'bcd')
      this.bcd2vni = apiutil.dict_by_value_of_array(res.vxlan_list, 'bcd')
      this.bcd_seclvl_by_value = apiutil.dict_by_value_of_array(res.bcd_seclvl_list, 'value')
      this.bcd_categ_by_name = apiutil.dict_by_value_of_array(res.bcd_categ_list, 'name')
      this.netcmpnds_by_net_instnc = apiutil.dict_of_lists_by_value_of_array(res.net_cmpnd2instnc_list, 'net_instnc')
      this.netcmpnds_by_name = apiutil.dict_by_value_of_array(res.net_cmpnd_list, 'name')
    }
  }
}
</script>
